import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/home';
import Loginpage from './pages/login';
import Signuppage from './pages/signup';
import Dashboardpage from './pages/dashboard';

function App() {
  return (
    <div className='App w-screen antialiased'>
        <Router>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/login" element={<Loginpage />}  />
            <Route path="/signup" element={<Signuppage />} />
            <Route path="/dashboard" element={<Dashboardpage />} />
          </Routes>
      </Router>
    </div>
  );
}

export default App;
