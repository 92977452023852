import React, { useEffect } from 'react'
import logo from '../assets/mslogo.png';
import { Link } from 'react-router-dom';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import pb from '../utils/pocketbase';
import { useNavigate } from 'react-router-dom';
import Home from './dashboardpages/home';
import Account from './dashboardpages/account';
import Support from './dashboardpages/support';
import Subscription from './dashboardpages/subscription';
import ErrorIcon from '@mui/icons-material/Error';

function Dashboardpage() {
    const [activetab, setActivetab] = React.useState('home');
    const navigate = useNavigate();


    useEffect(() => {
        if (!pb.authStore.isValid) {
            navigate('/login');
        }
    })

  return (
    <>
        {
            pb.authStore.isValid &&
            <div className='w-screen h-screen flex flex-col overflow-x-hidden'>
                <div className='w-full p-3 text-center border-b border-zinc-200 flex justify-center gap-x-2 sticky top-0 bg-white'>
                    {
                        pb.authStore.model.verified ?
                        <></> :
                        <>
                            <ErrorIcon sx={{color: 'red'}} />
                            <p>Your email is not verified.</p>
                        </>
                    }
                </div>
                <div className='flex w-full flex-grow'>
                    <div className='w-64 border-r border-zinc-200 hidden md:block fixed h-full'>
                        <div className='flex justify-center py-10'>
                            <Link className='w-fit' to={'/dashboard'}>
                                <img className='w-12' alt='logo' src={logo}></img>
                            </Link>
                        </div>
                        <div className='flex flex-col gap-y-4 px-4'>
                            <div onClick={() => setActivetab('home')} className={`w-full px-3 py-2 rounded-xl flex gap-2 items-center cursor-pointer ${activetab === 'home' ? 'bg-zinc-700 border border-zinc-600 text-white' : 'text-zinc-700'} `}>
                                <HomeRoundedIcon sx={{width: '30px', height: '25px'}} />
                                <div>
                                    Home
                                </div>
                            </div>
                            <div onClick={() => setActivetab('account')} className={`w-full px-3 py-2 rounded-xl flex gap-2 items-center cursor-pointer ${activetab === 'account' ? 'bg-zinc-700 border border-zinc-600 text-white' : 'text-zinc-700'} `}>
                                <Person2RoundedIcon sx={{width: '30px', height: '25px'}} />
                                <div>
                                    Account
                                </div>
                            </div>
                            <div onClick={() => setActivetab('subscription')} className={`w-full px-3 py-2 rounded-xl flex gap-2 items-center cursor-pointer ${activetab === 'subscription' ? 'bg-zinc-700 border border-zinc-600 text-white' : 'text-zinc-700'} `}>
                                <AddCardRoundedIcon sx={{width: '30px', height: '25px'}} />
                                <div>
                                    Subscription
                                </div>
                            </div>
                            <div onClick={() => setActivetab('support')} className={`w-full px-3 py-2 rounded-xl flex gap-2 items-center cursor-pointer ${activetab === 'support' ? 'bg-zinc-700 border border-zinc-600 text-white' : 'text-zinc-700'} `}>
                                <SupportRoundedIcon sx={{width: '30px', height: '25px'}} />
                                <div>
                                    Help & Support
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-64 h-fit border-r border-zinc-200 hidden md:block'>

                    </div>
                    <div className='flex-grow flex h-fit'>
                        {
                            activetab === 'home' && <Home />
                        }
                        {
                            activetab === 'account' && <Account />
                        }
                        {
                            activetab === 'subscription' && <Subscription />
                        }
                        {
                            activetab === 'support' && <Support />
                        }
                    </div>
                </div>
                <div className='w-full md:hidden border-t border-zinc-200 grid grid-cols-4 items-center box-border'>
                    <div className={`col-span-1 flex justify-center`}>
                        <div onClick={() => setActivetab('home')} className={`flex gap-2 items-center cursor-pointer py-3 w-full justify-center text-black`}> 
                            <HomeRoundedIcon sx={{width: '30px', height: '30px'}} />
                        </div>
                    </div>
                    <div className={`col-span-1 flex justify-center`}>
                        <div onClick={() => setActivetab('account')} className={`flex gap-2 items-center cursor-pointer py-3 w-full justify-center text-black`}> 
                            <Person2RoundedIcon sx={{width: '30px', height: '30px'}} />
                        </div>
                    </div>
                    <div className={`col-span-1 flex justify-center`}>
                        <div onClick={() => setActivetab('subscription')} className={`flex gap-2 items-center cursor-pointer py-3 w-full justify-center text-black`}> 
                            <AddCardRoundedIcon sx={{width: '30px', height: '30px'}} />
                        </div>
                    </div>
                    <div className={`col-span-1 flex justify-center`}>
                        <div onClick={() => setActivetab('support')} className={`flex gap-2 items-center cursor-pointer py-3 w-full justify-center text-black`}> 
                            <SupportRoundedIcon sx={{width: '30px', height: '30px'}} />
                        </div>
                    </div>
                </div>
            </div>
        }
    </>
  )
}

export default Dashboardpage