import React from 'react'
import logo from '../assets/mslogofilled.png';
import { Link } from 'react-router-dom';
import pb from '../utils/pocketbase';
import { useNavigate } from 'react-router-dom';

function Loginpage() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  const navigate = useNavigate();

  const login = async () => {
    try {
      const authData = await pb.collection('users').authWithPassword(email, password);
      console.log(authData);
      navigate('/dashboard');
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (pb.authStore.isValid) {
      navigate('/dashboard');
    }
  });


  return (
    <div className='w-screen h-screen bg-zinc-800 flex text-white font-light'>
        <div className='w-full flex-grow-0 mt-6 md:w-1/2 lg:w-1/3 flex justify-center items-center'>
            <div className='w-4/5 sm:w-2/3 flex flex-col gap-y-10'>
                <div className='flex justify-center'>
                    <Link className='w-fit' to={'/'}>
                        <img className='w-16' alt='logo' src={logo}></img>
                    </Link>
                </div>
                <div className='grid gap-y-2'>
                    <h1 className='text-3xl font-medium text-center'>MailStarter Login</h1>
                    <p className='text-sm text-center'>Get started with MailStarter</p>
                </div>
                <div className='grid gap'>
                    <div className='grid gap-2'>
                        <label className='ms-label-dark'>Email</label>
                        <input onChange={(e) => setEmail(e.target.value)} className='ms-input w-full'></input>
                    </div>
                    <div className='grid gap-2'>
                        <label className='ms-label-dark'>Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} className='ms-input w-full'></input>
                    </div>
                </div>
                <button onClick={login} className='ms-button-primary w-full'>Login</button>
                <p className='text-sm text-center'>Do not have an account? <Link className='text-blue-500' to={'/signup'}>Sign up</Link></p>
            </div>
        </div>
        <div className='flex-grow ms-bg-gradient hidden md:block'>

        </div>
    </div>
  )
}

export default Loginpage