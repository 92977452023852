import React from 'react'
import logo from '../assets/mslogofilled.png';
import { Link } from 'react-router-dom';

function Homepage() {
  return (
    <div className='w-full bg-zinc-800 min-h-screen text-white text-sm sm:text-base'>
        <div className='mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl'>
            <div className='w-full h-16 flex justify-between items-center'>
                <div>
                    <img className='w-10' src={logo} alt='logo'/>
                </div>
                <div className='flex-grow  justify-center lg:gap-x-12 hidden sm:flex'>
                    <a className='mr-4 ' href='/'>Pricing</a>
                    <a className='mr-4 ' href='/'>Features</a>
                    <a className='mr-4 ' href='/'>Blog</a>
                </div>
                <div>
                    <Link to={'/signup'}>
                        <button className='bg-blue-500 text-white font-medium py-2 px-4 rounded-xl mr-4 min-w-32 shadow-md shadow-blue-600'>Signup</button>
                    </Link>
                    <Link to={'/login'}>
                        <button className='border-zinc-300 border-solid border text-white font-medium py-2 px-4 rounded-xl min-w-32 shadow-white'>Login</button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Homepage