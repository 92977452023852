import React, { useEffect } from 'react'
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import pb from '../../utils/pocketbase';
import { useNavigate } from 'react-router-dom';

function Account() {
    const [name , setName] = React.useState(pb.authStore.model.name);
    const [password, setPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');
    const [oldPassword, setOldPassword] = React.useState('');

    const navigate = useNavigate();

    const updateaccount = async () => { 
        try {
            await pb.collection('users').update(pb.authStore.model.id, {
                name: name
            });
            console.log('success');
        } catch (error) {
            console.log(error);
        }
    }

    const updatepassword = async () => {
        try {
            await pb.collection('users').update(pb.authStore.model.id, {
                password: password,
                passwordConfirm: repeatPassword,
                oldPassword: oldPassword
            });
            console.log('success');
            pb.authStore.clear();
            navigate('/login');
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (pb.authStore.isValid === false) {
            // If invalid, redirect to the login page
            navigate('/login');
        }
    }, [navigate]);

  return (
    <div className='flex-grow flex m-10 border border-zinc-200 bg-slate-50 rounded-xl box-border'>
        <div className='p-6 w-full'>
            <div className='text-zinc-700 flex items-center border-b border-zinc-300 py-6'>
                <AccountCircleRoundedIcon sx={{width: '70px', height: '70px'}}/>
                <div className='ml-6'>
                    <p className='font-bold'>{pb.authStore.model.username}</p>
                    <p className=''>{pb.authStore.model.email}</p>
                </div>
            </div>
            <div className='my-6 flex flex-col sm:w-2/3 lg:w-3/6 xl:w-2/6 gap-y-2'>
                <div className='flex flex-col gap-y-2'>
                    <label className=''>Name</label>
                    <input onChange={(e) => setName(e.target.value)} defaultValue={pb.authStore.model.name} className='ms-input-light w-full'></input>
                    <label className=''>Email</label>
                    <input disabled defaultValue={pb.authStore.model.email} className='ms-input-light w-full'></input>
                    <label className=''>Username</label>
                    <input disabled defaultValue={pb.authStore.model.username} className='ms-input-light w-full'></input>
                    <button onClick={updateaccount} className='ms-button-secondary px-10 w-fit mt-3'>Update</button>
                </div>
                <div className='flex flex-col gap-y-2 mt-6'>
                    <label className=''>Pasword</label>
                    <input onChange={(e) => setPassword(e.target.value)} className='ms-input-light w-full'></input>
                    <label className=''>Password repeat</label>
                    <input onChange={(e) => setRepeatPassword(e.target.value)} className='ms-input-light w-full'></input>
                    <label className=''>Old password </label>
                    <input onChange={(e) => setOldPassword(e.target.value)} className='ms-input-light w-full'></input>
                    <p className='text-sm'>Changing your password will log you out</p>
                    <button onClick={updatepassword} className='ms-button-secondary px-10 w-fit mt-6'>Change Password</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Account