import React, { useEffect } from 'react'
import logo from '../assets/mslogofilled.png';
import { Link, useNavigate } from 'react-router-dom';
import pb from '../utils/pocketbase';

function Signuppage() {
    const [name, setName] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [email, setEmail] = React.useState('default@example.com');
    const [password, setPassword] = React.useState('');
    const [repeatPassword, setRepeatPassword] = React.useState('');

    const navigate = useNavigate();


    const signupdata = {
        name: name,
        username: username,
        email: email,
        password: password,
        passwordConfirm: repeatPassword,
        emailVisibility: true,
        role: 'user'
    }



    
    const signup = async () => {
        try {
            if (password === repeatPassword) {
                console.log(signupdata);
                const record = await pb.collection('users').create(signupdata);
                console.log(record);
            } else {
                console.log('Passwords do not match');
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        pb.authStore.isValid && navigate('/dashboard');
    })

  return (
    <div className='w-screen h-screen bg-zinc-800 flex text-white font-light'>
        <div className='w-full flex-grow-0 mt-6 md:w-1/2 lg:w-1/3 flex justify-center items-center'>
            <div className='w-4/5 sm:w-2/3 flex flex-col gap-y-10'>
                <div className='flex justify-center'>
                    <Link className='w-fit' to={'/'}>
                        <img className='w-16' alt='logo' src={logo}></img>
                    </Link>
                </div>
                <div className='grid gap-y-2'>
                    <h1 className='text-3xl font-medium text-center'>MailStarter Sign up</h1>
                    <p className='text-sm text-center'>Get started with MailStarter</p>
                </div>
                <div className='grid gap'>
                    <div className='grid gap-2'>
                        <label className='ms-label-dark'>Name</label>
                        <input onChange={(e) => setName(e.target.value)} className='ms-input w-full'></input>
                    </div>
                    <div className='grid gap-2'>
                        <label className='ms-label-dark'>Username</label>
                        <input onChange={(e) => setUsername(e.target.value)} className='ms-input w-full'></input>
                    </div>
                    <div className='grid gap-2'>
                        <label className='ms-label-dark'>Email</label>
                        <input onChange={(e) => setEmail(e.target.value)} className='ms-input w-full'></input>
                    </div>
                    <div className='grid gap-2'>
                        <label className='ms-label-dark'>Password</label>
                        <input onChange={(e) => setPassword(e.target.value)} className='ms-input w-full'></input>
                    </div>
                    <div className='grid gap-2'>
                        <label className='ms-label-dark'>Password (repeat)</label>
                        <input onChange={(e) => setRepeatPassword(e.target.value)} className='ms-input w-full'></input>
                    </div>
                </div>
                <button onClick={signup} className='ms-button-primary w-full'>Sign up</button>
                <p className='text-sm text-center'>Already have an account? <Link className='text-blue-500' to={'/login'}>Login</Link></p>
            </div>
        </div>
        <div className='flex-grow ms-bg-gradient hidden md:block'>

        </div>
    </div>
  )
}

export default Signuppage